import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [2];

export const dictionary = {
		"/(auth)": [~7,[2]],
		"/(auth)/account": [8,[2]],
		"/(no-auth)/(glowing-bg)/account/reset-password": [26,[6]],
		"/(no-auth)/(glowing-bg)/account/reset-password/confirm": [27,[6]],
		"/(no-auth)/(glowing-bg)/account/sign-in": [~28,[6]],
		"/(no-auth)/(glowing-bg)/account/sign-up": [29,[6]],
		"/(no-auth)/(glowing-bg)/account/verify-email": [30,[6]],
		"/(auth)/admin/api-docs": [9,[2]],
		"/(auth)/admin/backend": [10,[2]],
		"/(auth)/admin/overview": [11,[2]],
		"/(no-auth)/(glowing-bg)/demo": [31,[6]],
		"/(auth)/for-you": [~12,[2]],
		"/(auth)/for-you/(onboarding)/complete-resume": [~13,[2,3]],
		"/(auth)/for-you/(onboarding)/job-preferences": [14,[2,3]],
		"/(auth)/for-you/(onboarding)/review-jobs": [15,[2,3]],
		"/(auth)/for-you/(onboarding)/select-resume": [16,[2,3]],
		"/(auth)/for-you/(onboarding)/start": [17,[2,3]],
		"/(auth)/jobs": [18,[2]],
		"/(auth)/jobs/[slug]": [19,[2]],
		"/(auth)/profile/job-preferences": [20,[2,4]],
		"/(auth)/profile/my-profile": [21,[2,4]],
		"/(auth)/profile/my-profile/resumes/new": [23,[2,4,5]],
		"/(auth)/profile/my-profile/resumes/[id]": [22,[2,4,5]],
		"/(auth)/profile/qualifications": [24,[2,4]],
		"/(no-auth)/sentry/test": [33],
		"/(auth)/support": [25,[2]],
		"/(no-auth)/(glowing-bg)/welcome": [32,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';